import { BrowserRouter as Router, Route } from "react-router-dom";
import MainPage from './MainPage'
import Scan from './Scan'

import "./App.css";

function App() {
  return (
    <Router>
        <Route path="/" exact component={MainPage} />
        <Route path="/scan" component={Scan} />
    </Router>
  );
}

export default App;
