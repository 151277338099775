import hash from "object-hash";
import { DateTime } from "luxon";

// Config Storage

const storageKey = "DMCConfig";
const loadConfigs = () => JSON.parse(localStorage.getItem(storageKey)) || {};
const storeConfigs = (configs) =>
  localStorage.setItem(storageKey, JSON.stringify(configs));

export const configHash = (c) => hash(c);

export const saveConfig = (name, config) => {
  const configs = loadConfigs();
  configs[name] = {
    hash: hash(config),
    timestamp: DateTime.now().toISO(),
    config,
  };
  storeConfigs(configs);
};

export const getConfigs = () => loadConfigs();
export const getConfig = (name) => {
  const configs = loadConfigs();
  return { name, ...configs[name] };
};

export const nameExists = (name) => {
  const configs = loadConfigs();
  return Object.keys(configs).includes(name);
};

export const deleteConfig = (name) => {
  const configs = loadConfigs();
  delete configs[name];
  storeConfigs(configs);
};

// History
const historyKey = "DMCHistory";
const loadHistory = () => JSON.parse(localStorage.getItem(historyKey)) || [];
const saveHistory = (history) =>
  localStorage.setItem(historyKey, JSON.stringify(history));

export const getHistory = () => {
  const history = loadHistory();
  const configs = loadConfigs();
  return history
    .map((h) => {
      const name = Object.keys(configs).find((k) => configs[k].hash === h.hash);
      return {
        name,
        ...h,
      };
    })
    .reverse();
};
export const getHistoryItem = (timestamp) => {
  const history = loadHistory();
  const item = history.find((i) => i.timestamp === timestamp);
  if (item) {
    const configs = loadConfigs();
    const name = Object.keys(configs).find((k) => configs[k].hash === item.hash);
    return {
      name, 
      ...item
    }
  }
  return item
};

export const addHistoryItem = (config) => {
  const history = loadHistory();
  history.push({
    timestamp: DateTime.now().toISO(),
    hash: hash(config),
    config,
  });
  saveHistory(history);
};
