const closestIndex = (num, arr) => {
  let curr = arr[0],
    diff = Math.abs(num - curr);
  let index = 0;
  for (let val = 0; val < arr.length; val++) {
    let newdiff = Math.abs(num - arr[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val];
      index = val;
    }
  }
  return index;
};

export const smartSensitivity = (operatingPoint, range, calibration) => {
  if (calibration?.ranges) {
    // Sensitivity based on range
    for (let i = 0; i < calibration.ranges.length; i++) {
      const { min, max, sensitivity } = calibration.ranges[i];
      if (range >= min && range <= max) {
        return sensitivity;
      }
    }
    return 0;
  } else {
    // Static sensitivity or based on setpoint
    const sensitivities = calibration?.sensitivities || [0];
    return calibration?.setpoints
      ? sensitivities[closestIndex(operatingPoint, calibration?.setpoints)]
      : sensitivities[0];
  }
};

const labAmpRanges = {
  charge: {
    unit: "pC",
    ranges: [1e6, 1e5, 1e4, 1e3, 1e2],
    sensitivityFactor: 1,
  },
  iepe: {
    unit: "V",
    ranges: [10, 1],
    sensitivityFactor: 1000,
  },
  voltage: {
    unit: "V",
    ranges: [10, 1],
    sensitivityFactor: 1000,
  },
};

export const smartRange = (stage, sensitivity, physicalRange) => {
  if (!(stage && sensitivity && physicalRange)) {
    return { unit: undefined, range: 0 };
  }

  const stageRanges = labAmpRanges[stage.toLowerCase()];
  const inputRange =
    (Math.abs(physicalRange) * sensitivity) / stageRanges.sensitivityFactor;

  let smRange = stageRanges.ranges[0];
  const overdrive = inputRange > smRange;
  if (!overdrive) {
    for (let i = 1; i < stageRanges.ranges.length; i++) {
      if (inputRange <= stageRanges.ranges[i]) {
        smRange = stageRanges.ranges[i];
      }
    }
  }

  return {
    unit: stageRanges.unit,
    range: smRange,
    overdrive,
  };
};

const labAmpSamplingRates = [
  200000, 156250, 125000, 100000, 78125, 62500, 50000, 31250, 25000,
  12500, 10000, 6250, 2500, 1000, 100, 10,
];
export const maxLabAmpFreq = labAmpSamplingRates[0] / 2.5
export const smartSamplingRate = (channels) => {
  const cutoffFreq = channels.reduce((acc, current) => Math.max(acc, current.cutoffFrequency), 0)
  const minSamplingRate = cutoffFreq * 2.5
  for (let i = labAmpSamplingRates.length; i >= 0; i--) {
    if (labAmpSamplingRates[i] > minSamplingRate) {
      return labAmpSamplingRates[i]
    }
  }
  return labAmpSamplingRates[0]
}

/* 
  From https://wiki.kistler.com/x/mAiEAQ on 2021-06-12:

 IEPE Sensors (e.g. 8763B):
  Ground isolated and differential sensors are preferable for long cable length applications

  Calculation:
  I = 2 * π * f * C * E

  I = Current in Amperes
  f = Frequency in Hz
  C = Capacitance in Farads (typically 98 pF/m)
  E = Output in Volts, Peak
*/
export const fmax_iepe_hz = (iSupp_mA, length_m) => Math.ceil((iSupp_mA / 1000) / (2 * Math.PI * 5 * length_m * 98e-12))

// A very rudimentary deep copy
export const deepCopy = o => JSON.parse(JSON.stringify(o))