import React from "react";
import {
  Icon,
  Image,
  Grid,
  Button,
  Placeholder,
  Label,
  Popup,
} from "semantic-ui-react";
import { DateTime } from "luxon";

export const SensorDetails = ({ sensor }) => {
  if (!sensor) {
    return "-";
  }

  const calibrationDate = DateTime.fromISO(sensor.calibrationDate);
  const isOldCalibration = Boolean(
    DateTime.now().diff(calibrationDate).as("years") > 1
  );
  const dateLabel = (
    <Label horizontal color={isOldCalibration ? "orange" : "green"} basic>
      {calibrationDate.toLocaleString()}
    </Label>
  );

  return (
    <Grid columns={2} verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          {sensor?.type && (
            <Image src={`/sensors/${sensor?.type}.png`} size="tiny" />
          )}
          {!sensor?.type && sensor?.serial && (
            <Placeholder style={{ height: "80px", width: "80px" }}>
              <Placeholder.Image />
            </Placeholder>
          )}
        </Grid.Column>
        <Grid.Column>
          <p>
            <b>Type:</b> {sensor?.type}
          </p>
          <p>
            <b>Measurand:</b> {sensor?.measurand}
          </p>
          <p>
            <b>Class:</b> {sensor?.class}
          </p>
          <p>
            <b>Serial:</b> {sensor?.serial}
          </p>
          <p>
            <b>Components:</b> {sensor?.components}
          </p>
          <p>
            <b>Calibration Date:</b>{" "}
            {isOldCalibration && (
              <Popup
                content="Your calibration data is older than one year and might be inaccurate. Please contact Kistler support for a re-calibration."
                trigger={dateLabel}
              />
            )}
            {!isOldCalibration && dateLabel}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            as="a"
            href={`/sensors/${sensor?.type}.pdf`}
            target="_blank"
            icon
            disabled={!sensor?.type}
          >
            <Icon name="file outline pdf" /> Datasheet
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button
            as="a"
            href={`/sensors/${sensor?.serial}.pdf`}
            target="_blank"
            icon
            disabled={!sensor?.serial}
          >
            <Icon name="file outline pdf" /> Calibration
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const CableDetails = ({ cable }) => {
  if (!cable) {
    return "";
  }

  return (
    <Grid columns={2} verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          {cable?.type && (
            <Image src={`/cables/${cable?.type}.png`} size="tiny" />
          )}
        </Grid.Column>
        <Grid.Column>
          <p>
            <b>Type:</b> {cable?.type}
          </p>
          <p>
            <b>Length:</b> {cable?.length}
            {cable?.length && "m"}
          </p>
          <p>
            <b>Components:</b> {cable?.components}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            as="a"
            href={`/cables/${cable?.type}.pdf`}
            target="_blank"
            icon
            disabled={!cable?.type}
          >
            <Icon name="file outline pdf" /> Datasheet
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
