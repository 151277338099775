import React, { useState } from "react";
import { Table, Button, Icon, Confirm, Pagination } from "semantic-ui-react";
import { DateTime } from "luxon";

import { getConfigs, deleteConfig } from "./ConfigStorage";

const ConfigTable = ({ select, selected, actions }) => {
  const selectFunction = select ? select : () => {};

  const configs = getConfigs();
  const configsArray = Object.keys(configs)
    .map((k) => ({
      name: k,
      ...configs[k],
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  // Pagination
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(configsArray.length / pageSize);

  const configsPage = configsArray.slice(
    pageSize * currentPage,
    pageSize * currentPage + pageSize
  );
  
  const [nameToDelete, setNameToDelete] = useState(undefined);
  return (
    <React.Fragment>
      <Table
        selectable
        tableData={configsPage}
        renderBodyRow={(config) => (
          <Table.Row
            onClick={() => {
              if (config.name === selected) {
                selectFunction(undefined)
              } else {
                selectFunction(config.name);
              }
            }}
            active={config.name === selected}
            key={config.hash}
          >
            <Table.Cell>{config.name}</Table.Cell>
            <Table.Cell>
              {DateTime.fromISO(config.timestamp).toLocaleString(
                DateTime.DATETIME_MED_WITH_SECONDS
              )}
            </Table.Cell>
            <Table.Cell>{config.hash.substr(0, 7)}</Table.Cell>
            {actions && (
              <Table.Cell>
                <Button
                  icon
                  onClick={(e) => {
                    e.stopPropagation();
                    setNameToDelete(config.name);
                  }}
                >
                  <Icon name="trash" />
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        )}
        headerRow={
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Metrological ID</Table.HeaderCell>
            {actions && <Table.HeaderCell>Actions</Table.HeaderCell>}
          </Table.Row>
        }
      />
      <div style={{ textAlign: "center" }}>
        <Pagination
          activePage={currentPage + 1}
          totalPages={totalPages}
          onPageChange={(_, data) => {
            setCurrentPage(data.activePage - 1);
          }}
        />
      </div>
      <Confirm
        open={Boolean(nameToDelete)}
        content={`Are you sure to delete configuration ${nameToDelete}?`}
        size="tiny"
        onCancel={() => setNameToDelete(undefined)}
        onConfirm={() => {
          deleteConfig(nameToDelete);
          if (nameToDelete === selected) {
            select(undefined);
          }
          setNameToDelete(undefined);
        }}
      />
    </React.Fragment>
  );
};

export default ConfigTable;
