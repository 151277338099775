import React, { useState } from "react";
import {
  Header,
  Table,
  Icon,
  Input,
  Menu,
  Image,
  Container,
  Button,
  Grid,
  Segment,
  Message,
  Confirm,
  Modal,
} from "semantic-ui-react";

import useWebSocket from "react-use-websocket";

import ChainEdit from "./ChainEdit";
import ConfigTable from "./ConfigTable";
import HistoryTable from "./HistoryTable";
import { SensorDetails, CableDetails } from "./ComponentDetails";

import { saveConfig, configHash, nameExists, getConfig, addHistoryItem, getHistoryItem } from "./ConfigStorage";

import {
  smartSensitivity,
  smartRange,
  smartSamplingRate,
  maxLabAmpFreq,
  fmax_iepe_hz,
  deepCopy,
} from "./Util";

const Row = ({
  device,
  chain,
  channel,
  samplingRate,
  setName,
  setOperatingPoint,
  setCutoffFrequency,
  setRange,
  resetChannels,
  editChain,
}) => {
  const component = channel.number - chain?.startChannel;

  // Smart Supply Current(tm)
  const hasSupplyCurrent = Boolean(chain?.sensor?.class === "IEPE");
  const fmax_4mA = fmax_iepe_hz(4, chain?.cable?.length);
  const fmax_10mA = fmax_iepe_hz(10, chain?.cable?.length);

  const maxCutoffFrequency = hasSupplyCurrent
    ? Math.min(fmax_10mA, maxLabAmpFreq)
    : maxLabAmpFreq;

  if (
    (channel?.cutoffFrequency === -1 || channel?.cutoffFrequency > fmax_10mA) &&
    chain
  ) {
    setCutoffFrequency(Math.min(fmax_10mA, maxCutoffFrequency));
  }

  // Smart Sensitivity(tm)
  const setpoints = chain?.sensor?.calibration[component]?.setpoints;
  const setpointUnit = chain?.sensor?.calibration[component]?.setpointUnit;
  const initialSetpointIndex = setpoints ? Math.floor(setpoints.length / 2) : 0;
  if (setpoints && channel.operatingPoint === -1) {
    console.log(channel.operatingPoint);
    setOperatingPoint(setpoints[initialSetpointIndex]);
  }

  const sensitivity = smartSensitivity(
    channel?.operatingPoint,
    channel?.range,
    chain?.sensor?.calibration[component]
  );

  // Smart Range(tm)
  const componentRange = chain?.sensor?.calibration[component]?.range;
  const minRange = chain?.sensor?.calibration[component]?.minRange || 0;
  if ((channel?.range === -1 || channel?.range > componentRange) && chain) {
    setRange(componentRange * 0.5);
  }
  const {
    range,
    unit: rangeUnit,
    overdrive,
  } = smartRange(chain?.sensor?.class, sensitivity, channel?.range);

  return (
    <Table.Row key={channel.number} verticalAlign="top">
      {(!chain || chain?.startChannel === channel.number) && (
        <React.Fragment>
          <Table.Cell rowSpan={chain?.components || 1} width={1}>
            {chain && (
              <React.Fragment>
                <Button
                  primary
                  compact
                  icon
                  circular
                  onClick={() => {
                    editChain(chain);
                  }}
                >
                  <Icon name="edit" />
                </Button>
                <br />
                <br />
                <Button
                  negative
                  compact
                  icon
                  circular
                  onClick={() => {
                    fetch(`/api/detach/channel/${channel.number}`, {
                      method: "POST",
                    }).then((res) => {
                      if (res.ok) {
                        // reset affected channels
                        resetChannels();
                      }
                    });
                  }}
                >
                  <Icon name="delete" />
                </Button>
              </React.Fragment>
            )}
          </Table.Cell>
          <Table.Cell
            rowSpan={chain?.components || 1}
            width={3}
            verticalAlign="middle"
          >
            <SensorDetails sensor={chain?.sensor} />
          </Table.Cell>
          <Table.Cell
            rowSpan={chain?.components || 1}
            width={3}
            verticalAlign="middle"
          >
            <CableDetails cable={chain?.cable} />
          </Table.Cell>
        </React.Fragment>
      )}
      {channel.number === 1 && (
        <Table.Cell width={2} rowSpan={4} verticalAlign="middle">
          <Grid columns={1} verticalAlign="middle">
            <Grid.Row>
              <Grid.Column>
                <Image src={`/labamp.png`} size="tiny" />
                <p>
                  <b>Type:</b> {device.type}
                </p>
                <p>
                  <b>Serial:</b> {device.serial}
                </p>
                <p>
                  <b>Channels:</b> {device.channels}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button as="a" href={`/5165A.pdf`} target="_blank" icon>
                  <Icon name="file outline pdf" /> Datasheet
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      )}
      <Table.Cell textAlign="center" verticalAlign="middle">
        <Icon name="arrow right" size="big"></Icon>
      </Table.Cell>
      <Table.Cell width={2} verticalAlign="middle">
        <Grid columns={1} verticalAlign="middle">
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">
                <Icon color="blue" name="dot circle outline" />
                <Header.Content>{channel.number}</Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input
                placeholder="Channel Name"
                value={channel.name}
                style={{ width: "100%" }}
                onChange={(_, data) => {
                  setName(data.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.Cell>
      <Table.Cell width={4}>
        {chain && (
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div style={{ marginBottom: "5px" }}>
                  Cutoff Frequency: <b>{channel?.cutoffFrequency} Hz</b>
                </div>
                <input
                  type="range"
                  min={0}
                  max={maxCutoffFrequency}
                  value={channel?.cutoffFrequency}
                  onChange={(e) => {
                    setCutoffFrequency(e.target.value);
                  }}
                />
                {setpoints && (
                  <React.Fragment>
                    <div style={{ marginBottom: "5px" }}>
                      Operating Point:{" "}
                      <b>
                        {channel.operatingPoint} {setpointUnit}
                      </b>
                    </div>
                    <input
                      type="range"
                      min={setpoints[0]}
                      max={Math.min(
                        channel?.cutoffFrequency,
                        setpoints[setpoints.length - 1]
                      )}
                      value={channel.operatingPoint}
                      onChange={(e) => {
                        setOperatingPoint(e.target.value);
                      }}
                    />
                  </React.Fragment>
                )}
                <div style={{ marginBottom: "5px" }}>
                  Maximal {chain?.sensor?.measurand}:{" "}
                  <b>
                    {channel.range} {chain?.sensor?.measurandUnit}
                  </b>
                </div>
                <input
                  type="range"
                  min={minRange}
                  max={componentRange}
                  value={channel.range}
                  onChange={(e) => {
                    setRange(e.target.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <p>
                  Smart Sampling Rate&#8482;:
                  <br />
                  <b>{samplingRate} sps</b>
                </p>
                {hasSupplyCurrent && (
                  <p>
                    Smart Supply Current&#8482;:
                    <br />
                    <b>{channel?.cutoffFrequency > fmax_4mA ? 10 : 4} mA</b>
                  </p>
                )}
                <p>
                  Smart Sensitivity&#8482;:
                  <br />
                  <b>
                    {chain.sensor.outputUnit === "pC" ? "-" : ""}
                    {sensitivity} {chain.sensor.outputUnit}/
                    {chain.sensor.measurandUnit}
                  </b>
                </p>
                <p>
                  Smart Range&#8482;:
                  <br />
                  <b>
                    <span style={{ color: overdrive ? "red" : "#000" }}>
                      {range} {rangeUnit}
                    </span>
                  </b>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const initChannel = {
  name: " ",
  operatingPoint: -1,
  highSupplyCurrent: false,
  cutoffFrequency: -1,
  range: -1,
};
const getInitChannel = (number) => ({ number, ...initChannel });

const device = {
  type: "5165A",
  serial: 5714345,
  channels: 4,
};
const getInitConfig = () => ({
  device,
  channels: [1, 2, 3, 4].map((i) => getInitChannel(i)),
  chains: [],
});
const initHash = configHash(getInitConfig());

const MainPage = () => {
  // WebSocket Stuff
  const { lastJsonMessage, lastMessage } = useWebSocket(
    `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${
      window.location.host
    }/events`,
    {
      retryOnError: true,
      shouldReconnect: () => true,
    }
  );

  // Detect new events
  const [lastTimeStamp, setLastTimeStamp] = useState(null);
  let event = null;
  if (lastMessage && lastTimeStamp !== lastMessage.timeStamp) {
    event = lastJsonMessage;
    setLastTimeStamp(lastMessage.timeStamp);
  }

  // Attach/Edit Modal
  const [activeChain, setActiveChain] = useState(undefined);
  if (event?.type === "NEW_SENSOR" || event?.type === "NEW_CABLE") {
    let a = activeChain || {};
    if (event.type === "NEW_SENSOR") {
      a = { ...a, sensor: event.data };
    }
    if (event.type === "NEW_CABLE") {
      a = { ...a, cable: event.data };
    }
    setActiveChain(a);
  }

  // The Chains & Channels
  const [chains, setChains] = useState([]);
  const [channels, setChannels] = useState(getInitConfig().channels);

  if (event?.type === "CHAIN_UPDATE") {
    setChains(event.data);
  }
  const findChainForChannel = (i) =>
    chains.find(
      (c) => i >= c.startChannel && i <= c.startChannel + c.components - 1
    );

  const samplingRate = smartSamplingRate(channels);
  const rows = channels.map((channel, i) => {
    const chain = findChainForChannel(channel.number);
    return (
      <Row
        key={`channel-${i}`}
        device={device}
        chain={chain}
        channel={channel}
        samplingRate={samplingRate}
        setName={(newName) => {
          const newChannels = [...channels];
          newChannels[i].name = newName && newName.length ? newName : " ";
          setChannels(newChannels);
        }}
        setOperatingPoint={(newOpsPoint) => {
          const newChannels = [...channels];
          newChannels[i].operatingPoint = parseInt(newOpsPoint);
          setChannels(newChannels);
        }}
        setRange={(newRange) => {
          const newChannels = [...channels];
          newChannels[i].range = parseInt(newRange);
          setChannels(newChannels);
        }}
        setCutoffFrequency={(newCutoffFrequency) => {
          const newChannels = [...channels];
          newChannels[i].cutoffFrequency = parseInt(newCutoffFrequency);
          if (parseInt(newCutoffFrequency) < newChannels[i].operatingPoint) {
            newChannels[i].operatingPoint = parseInt(newCutoffFrequency);
          }
          setChannels(newChannels);
        }}
        resetChannels={() => {
          const newChannels = [...channels];
          for (
            let i = chain.startChannel;
            i < chain.startChannel + chain.components;
            i++
          ) {
            newChannels[i - 1] = {
              ...getInitChannel(i),
              name: channels[i - 1].name,
            };
          }
          setChannels(newChannels);
        }}
        editChain={(c) => setActiveChain(c)}
      />
    );
  });

  const [writing, setWriting] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const writeChannels = () => {
    setWriting(true);
    const writeChans = channels.map((c) => {
      const chain = findChainForChannel(c.number);
      if (chain) {
        const writeChan = {
          name: c.name.length ? c.name : " ",
          serialNumber: chain.sensor?.serial,
          stage: chain.sensor?.class.toLowerCase(),
        };

        const range = Math.abs(c.range);
        const basicStageData = {
          sensitivity: smartSensitivity(
            c.operatingPoint,
            range,
            chain.sensor?.calibration[c.number - chain.startChannel]
          ),
          sensitivityUnit: `${chain.sensor?.outputUnit}/${chain.sensor?.measurandUnit}`,
          physicalRange: [-range, range],
          physicalUnit: chain.sensor?.measurandUnit,
        };
        switch (writeChan.stage) {
          case "iepe":
            return {
              ...writeChan,
              stages: {
                iepe: {
                  ...basicStageData,
                  supplyCurrent:
                    c.cutoffFrequency > fmax_iepe_hz(4, chain.cable?.length)
                      ? "10mA"
                      : "4mA",
                },
              },
            };
          default:
            return {
              ...writeChan,
              stages: {
                [writeChan.stage]: basicStageData,
              },
            };
        }
      } else {
        return {
          name: " ",
          serialNumber: " ",
        };
      }
    });

    fetch("/api/settings", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ samplingRate, channels: writeChans }),
    })
      .then((res) => {
        if (res.ok) {
          setSuccessVisible(true);
          setTimeout(() => setSuccessVisible(false), 2000);
        } else {
          setErrorVisible(true);
          setTimeout(() => setErrorVisible(false), 2000);
        }
        setWriting(false);
      })
      .catch(() => {
        setErrorVisible(true);
        setTimeout(() => setErrorVisible(false), 2000);
      });
  };

  // Save/Load Configuration
  const [loadedConfig, setLoadedConfig] = useState(undefined);
  const resetConfig = () => {
    const newConfig = getInitConfig();
    setChains([...newConfig.chains]);
    setChannels([...newConfig.channels]);
    setLoadedConfig(undefined);
    fetch(`/api/chains`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newConfig.chains),
    });
  };
  const loadConfig = (newConfig) => {
    setChannels(deepCopy(newConfig.config.channels));
    setChains(deepCopy(newConfig.config.chains));
    setLoadedConfig(deepCopy(newConfig));
    fetch(`/api/chains`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(deepCopy(newConfig.config.chains)),
    });
  };

  const currentConfig = {
    device,
    channels,
    chains,
  };

  const loadedHash = loadedConfig ? configHash(loadedConfig.config) : undefined;
  const currentHash = configHash(currentConfig);

  const diverged = Boolean(loadedHash !== currentHash);

  const [resetConfirmOpen, toggleResetConfirm] = useState(false);
  const [clearAllConfirmOpen, toggleClearAllConfirm] = useState(false);

  const [saveDialogOpen, toggleSaveDialog] = useState(false);
  const [loadDialogOpen, toggleLoadDialog] = useState(false);

  const [configurationName, setConfigurationName] = useState(undefined);
  const existingName = nameExists(configurationName);

  const [historyDialogOpen, toggleHistoryDialog] = useState(false);
  const [selectedHistoryTimestamp, setSelectedHistoryTimestamp] = useState(undefined);

  return (
    <React.Fragment>
      {activeChain && (
        <ChainEdit
          chain={activeChain}
          close={() => setActiveChain(undefined)}
        />
      )}
      <Menu size="huge">
        <Menu.Item>
          <Header>myKistler - Digital Measuring Chain</Header>
        </Menu.Item>
        <Menu.Item style={{ width: "50%" }}>
          <Grid verticalAlign="middle" style={{ width: "100%" }}>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>
                  Configuration:{" "}
                  <i>
                    {loadedConfig?.name || "unnamed"}
                    {loadedConfig && diverged ? "*" : ""}
                  </i>
                  <br />
                  Metrological ID:{" "}
                  <i>
                    {currentHash.substr(0, 7)}
                    {loadedConfig && diverged ? "*" : ""}
                  </i>
                </p>
              </Grid.Column>
              <Grid.Column width={11}>
                <Button
                  disabled={(loadedConfig?.name && !diverged) || currentHash === initHash}
                  primary
                  icon
                  onClick={() => {
                    setConfigurationName(loadedConfig?.name);
                    toggleSaveDialog(true);
                  }}
                >
                  <Icon name="save outline" /> Save
                </Button>
                <Modal
                  open={saveDialogOpen}
                  onClose={() => {
                    toggleSaveDialog(false);
                  }}
                  closeOnDimmerClick={false}
                >
                  <Modal.Header>Save Configuration</Modal.Header>
                  <Modal.Content>
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Input
                            label="Save as:"
                            placeholder="Configuration Name"
                            value={configurationName}
                            onChange={(e) => {
                              setConfigurationName(e.target.value);
                            }}
                            fluid
                          />
                        </Grid.Column>
                        <Grid.Column>
                          {existingName && (
                            <Message warning compact>
                              {`This will overwrite the existing configuration named ${configurationName}`}
                            </Message>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <ConfigTable
                      select={(name) => setConfigurationName(name)}
                      selected={configurationName}
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      icon
                      onClick={() => {
                        toggleSaveDialog(false);
                      }}
                    >
                      <Icon name="close" /> Cancel
                    </Button>
                    <Button
                      disabled={!Boolean(configurationName)}
                      icon
                      positive
                      onClick={() => {
                        saveConfig(configurationName, currentConfig);
                        setLoadedConfig({
                          name: configurationName,
                          config: deepCopy(currentConfig),
                        });
                        toggleSaveDialog(false);
                      }}
                    >
                      <Icon name="save outline" /> Save
                    </Button>
                  </Modal.Actions>
                </Modal>

                <Button
                  primary
                  icon
                  onClick={() => {
                    setConfigurationName(undefined);
                    toggleLoadDialog(true);
                  }}
                >
                  <Icon name="folder open outline" /> Load
                </Button>
                <Modal
                  open={loadDialogOpen}
                  onClose={() => {
                    toggleLoadDialog(false);
                  }}
                  closeOnDimmerClick={false}
                >
                  <Modal.Header>Load Configuration</Modal.Header>
                  <Modal.Content>
                    <ConfigTable
                      select={(name) => setConfigurationName(name)}
                      selected={configurationName}
                      actions
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      icon
                      onClick={() => {
                        toggleLoadDialog(false);
                      }}
                    >
                      <Icon name="close" /> Cancel
                    </Button>
                    <Button
                      disabled={!Boolean(configurationName)}
                      icon
                      positive
                      onClick={() => {
                        loadConfig(getConfig(configurationName));
                        toggleLoadDialog(false);
                      }}
                    >
                      <Icon name="folder open outline" /> Load
                    </Button>
                  </Modal.Actions>
                </Modal>

                <Button
                  disabled={!loadedConfig || currentHash === loadedHash}
                  icon
                  onClick={() => toggleResetConfirm(true)}
                >
                  <Icon name="undo" /> Reset
                </Button>
                <Confirm
                  open={resetConfirmOpen}
                  content={`Are you sure to reset to ${
                    loadedConfig?.name
                  } (${loadedHash?.substr(0, 7)})?`}
                  size="tiny"
                  onCancel={() => toggleResetConfirm(false)}
                  onConfirm={() => {
                    loadConfig(loadedConfig);
                    toggleResetConfirm(false);
                  }}
                />

                <Button
                  disabled={currentHash === initHash}
                  icon
                  onClick={() => toggleClearAllConfirm(true)}
                >
                  <Icon name="close" /> Clear
                </Button>
                <Confirm
                  open={clearAllConfirmOpen}
                  content="Are you sure to clear all configurations?"
                  size="tiny"
                  onCancel={() => toggleClearAllConfirm(false)}
                  onConfirm={() => {
                    resetConfig();
                    toggleClearAllConfirm(false);
                  }}
                />

              <Button
                  icon
                  onClick={() => {
                    setSelectedHistoryTimestamp(undefined)
                    toggleHistoryDialog(true)
                  }}
                  style={{marginLeft: '50px'}}
                >
                  <Icon name="list" /> History
                </Button>
                <Modal
                  open={historyDialogOpen}
                  onClose={() => {
                    toggleHistoryDialog(false);
                  }}
                  closeOnDimmerClick={false}
                >
                  <Modal.Header>Configuration History</Modal.Header>
                  <Modal.Content>
                    <HistoryTable
                      select={(timestamp) => setSelectedHistoryTimestamp(timestamp)}
                      selected={selectedHistoryTimestamp}
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      icon
                      onClick={() => {
                        toggleHistoryDialog(false);
                      }}
                    >
                      <Icon name="close" /> Close
                    </Button>
                    <Button
                      disabled={!Boolean(selectedHistoryTimestamp)}
                      icon
                      positive
                      onClick={() => {
                        loadConfig(getHistoryItem(selectedHistoryTimestamp));
                        toggleHistoryDialog(false);
                      }}
                    >
                      <Icon name="folder open outline" /> Load
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Menu.Item>
        <Menu.Item position="right" style={{ marginRight: "35px" }}>
          <Image src="kistler-logo.png" size="small" />
        </Menu.Item>
      </Menu>
      <Container fluid>
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Chain</Table.HeaderCell>
              <Table.HeaderCell>Sensor</Table.HeaderCell>
              <Table.HeaderCell>Cable</Table.HeaderCell>
              <Table.HeaderCell>DAQ</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Channel</Table.HeaderCell>
              <Table.HeaderCell>Smart Settings&#8482;</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
        <Segment textAlign="center">
          {!successVisible && !errorVisible && (
            <Button
              disabled={writing}
              loading={writing}
              color="green"
              icon
              onClick={() => {
                writeChannels()
                addHistoryItem(currentConfig)
              }}
            >
              <Icon name="download" /> Write Settings to Device
            </Button>
          )}
          <Message hidden={!successVisible} success>
            <Message.Header>Success</Message.Header>
            <p>The settings have been successfully written to the device.</p>
          </Message>
          <Message hidden={!errorVisible} error>
            <Message.Header>Error</Message.Header>
            <p>
              An error occurred when attempting to write the settings to the
              device.
            </p>
          </Message>
        </Segment>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
