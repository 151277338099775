import React, { useState } from "react";
import { Table, Pagination } from "semantic-ui-react";
import { DateTime } from "luxon";

import { getHistory } from "./ConfigStorage";

const HistoryTable = ({ select, selected }) => {
  const selectFunction = select ? select : () => {};

  const history = getHistory();

  // Pagination
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(history.length / pageSize);

  const historyPage = history.slice(
    pageSize * currentPage,
    pageSize * currentPage + pageSize
  );
  
  return (
    <React.Fragment>
      <Table
        selectable
        tableData={historyPage}
        renderBodyRow={(item) => (
          <Table.Row
            onClick={() => {
              if (item.timestamp === selected) {
                selectFunction(undefined)
              } else {
                selectFunction(item.timestamp);
              }
            }}
            active={item.timestamp === selected}
            key={item.timestamp}
          >
            <Table.Cell>
              {DateTime.fromISO(item.timestamp).toLocaleString(
                DateTime.DATETIME_MED_WITH_SECONDS
              )}
            </Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.hash.substr(0, 7)}</Table.Cell>
          </Table.Row>
        )}
        headerRow={
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Configuration Name</Table.HeaderCell>
            <Table.HeaderCell>Metrological ID</Table.HeaderCell>
          </Table.Row>
        }
      />
      <div style={{ textAlign: "center" }}>
        <Pagination
          activePage={currentPage + 1}
          totalPages={totalPages}
          onPageChange={(_, data) => {
            setCurrentPage(data.activePage - 1);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default HistoryTable;
