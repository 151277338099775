import React, { useState } from "react";
import {
  Header,
  Icon,
  Modal,
  Grid,
  Button,
  Message,
  Dropdown,
} from "semantic-ui-react";

import { SensorDetails, CableDetails } from "./ComponentDetails";

const ChainEdit = ({ chain, close }) => {
  const [targetChannel, setTargetChannel] = useState(chain?.startChannel);
  const [submitting, setSubmit] = useState(false);

  const wholeChain =
    Boolean(chain?.sensor?.components) && Boolean(chain?.cable?.components);

  const numberOfChannels = 4;
  const selections = [];
  for (let i = 1; i <= numberOfChannels - chain?.cable?.components + 1; i++) {
    selections.push({ start: i, end: i + chain?.cable?.components - 1 });
  }

  return (
    <Modal
      open={Boolean(chain)}
      onClose={() => {
        close();
      }}
      size="large"
      style={{ width: "90%" }}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>Edit Chain</Modal.Header>
      <Modal.Content>
        <Grid columns={3} relaxed="very">
          <Grid.Column>
            <Header as="h4">Sensor</Header>
            <SensorDetails sensor={chain?.sensor} />
          </Grid.Column>
          <Grid.Column>
            <Header as="h4">Cable</Header>
            <CableDetails cable={chain?.cable} />
          </Grid.Column>
          <Grid.Column>
            <Header as="h4">{`Channel${
              chain?.sensor?.components > 1 || chain?.cable?.components > 1
                ? "s"
                : ""
            }`}</Header>
            {wholeChain &&
              chain?.sensor?.components !== chain?.cable?.components && (
                <Message negative>
                  <Message.Header>Invalid Configuration</Message.Header>
                  <p>
                    A {chain?.sensor?.components}-component Sensor can't be
                    connected to a {chain?.cable?.components}-component Cable
                  </p>
                </Message>
              )}
            {wholeChain &&
              chain?.sensor?.components === chain?.cable?.components && (
                <div>
                  <p>{`Connect to Channel${
                    chain?.cable?.components > 1 ? "s" : ""
                  }`}</p>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Dropdown
                      selection
                      value={targetChannel || null}
                      onChange={(_, data) => {
                        setTargetChannel(data.value);
                      }}
                      placeholder={`Select Channel${
                        chain?.cable?.components > 1 ? "s" : ""
                      }`}
                      options={selections.map((s) => ({
                        key: s.start,
                        value: s.start,
                        text: `${s.start}${
                          s.start !== s.end ? ` - ${s.end}` : ""
                        }`,
                        icon: "dot circle outline",
                      }))}
                    />
                  </div>
                  <div>
                    <Button
                      positive
                      icon
                      disabled={submitting || !wholeChain || !targetChannel}
                      loading={submitting}
                      onClick={() => {
                        setSubmit(true);
                        fetch(`/api/attach/channel/${targetChannel}`, {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            sensorSerial: chain?.sensor?.serial,
                            cableType: chain?.cable?.type,
                          }),
                        }).then((res) => {
                          setSubmit(false);
                          if (!res.ok) {
                            return res.json();
                          } else {
                            close();
                          }
                        });
                      }}
                    >
                      <Icon name="save outline" /> Save & Connect
                    </Button>
                  </div>
                </div>
              )}
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default ChainEdit;
