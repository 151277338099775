import React, { useRef, useEffect } from "react";
import { Menu, Image, Container, Button, Divider } from "semantic-ui-react";

const Scan = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const serialRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" }, audio: false })
      .then((mediaSource) => {
        videoRef.current.srcObject = mediaSource;
      });
  }, []);

  const captureImage = async () => {
    // Make sure the Canvas is the same height as the video
    canvasRef.current.width = videoRef.current.offsetWidth;
    canvasRef.current.height = videoRef.current.offsetHeight;

    // Copy the video frame and stop the video
    canvasRef.current
      .getContext("2d")
      .drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    videoRef.current.srcObject
      .getVideoTracks()
      .forEach((track) => track.stop());

    // Upload the Image
    const b64 = canvasRef.current.toDataURL()
    const serial = await fetch("/api/detect", {
      method: "POST",
      body: JSON.stringify({
        image: b64.substring(b64.indexOf(',')),
      }),
      headers: {
        "content-type": "application/json",
      },
    }).then(res => res.text())
    serialRef.current.innerHTML = serial
  };

  return (
    <React.Fragment>
      <Menu size="huge" widths="1">
        <Menu.Item style={{ marginRight: "35px" }}>
          <Image src="kistler-logo.png" size="small" />
        </Menu.Item>
      </Menu>
      <Container textAlign="center">
        <div style={{maxWidth: '100%', maxHeight: "60%"}}>
        <video
          ref={videoRef}
          style={{ maxWidth: "600px", maxHeight: "600px" }}
          autoPlay
        ></video>
        </div>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        <Divider />
        <Button onClick={captureImage}>Submit</Button>
        <Divider />
        <h2 ref={serialRef}> </h2>
      </Container>
    </React.Fragment>
  );
};

export default Scan;
